<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <!-- 検索エリア -->
    <v-container>
      <v-row class="mt-2">
        <v-col cols="6" sm="6" md="5" lg="4">
          <h2>月次報告データ(8号・9号・輸入物資)一覧</h2>
        </v-col>
        <v-col cols="2" sm="2" md="2" lg="2">
          <DatePicker 
            label="対象年月" 
            v-model="searchCondition.targetMonth" 
            format="YYYY年MM月" 
            selectType="month" 
            :clearable="false" 
            suffix="分" 
            :loading="loading"
            :changeEvt="search"
            :rules="[checkDateUpper(upperDate, searchCondition.targetMonth)]"
          ></DatePicker>
        </v-col>
        <v-col v-if="attribute != 0" cols="3" md="2">
          <v-autocomplete 
            label="都道府県" 
            v-model="searchCondition.prefIdList" 
            :items="prefList" 
            multiple
            dense 
            hideDetails
            :disabled="loading"
            :loading="loading"
            @change="search"
          ></v-autocomplete>
        </v-col>
        <v-col v-if="attribute != 0" cols="3" md="2">
          <v-text-field
            label="検索"
            v-model="searchCondition.searchString"
            dense
            :readonly="false"
            :clearable="false"
            :rules="[maxLength(50)]"
            :loading="loading"
          ></v-text-field>
        </v-col>
<!--
        <v-spacer></v-spacer>
        <v-col cols="3" sm="3" md="2" lg="2">
          <v-btn class="" color="primary" width="200" :disabled="loading" @click="sendAnswer()"><v-icon :small="true">mdi-send</v-icon>回答送信</v-btn>
        </v-col>
-->
        <!-- <v-col>
          <v-btn class="primary" width="300" :disabled="loading" @click="gotoDetail(surveyId, memberNo)">{{ searchCondition.targetMonth | formatDate }}度データ新規作成</v-btn>
        </v-col> -->
      </v-row>
    </v-container>

    <v-container v-if="!isCheckDate">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12">
          <v-data-table 
            :headers="headers" 
            :items="officeList" 
            :loading="loading" 
            :dense="true" 
            :items-per-page="10" 
            :mobile-breakpoint="0" 
            :disable-pagination="true" 
            :hide-default-footer="true" 
            :fixed-header="true" 
            disable-sort 
            locale="ja-jp" 
            loading-text="読込中" 
            no-data-text="データがありません。" 
            class="table-border"
            :search="searchCondition.searchString"
            height="calc(100vh - 200px)"
          >
            <template v-slot:item="{ item }">
              <!-- <tr :key="item.memberNo" :class="[item.memberNo == memberNo ? 'selected-color' : '']"> -->
              <tr :key="item.memberNo">
                <td class="center">
                  {{ item.memberNo }}
                </td>
                <td class="left">
                  {{ item.companyName }}
                </td>
                <td class="left">
                  {{ item.officeName }}
                </td>
                <td class="left">
                  {{ item.address }}
                </td>
                <td class="center min-width-200">
                  <v-btn small class="primary" :height="20" :width="100" :disabled="loading" @click="gotoDetail(item.sid, item.memberNo)">
                    <v-icon v-if="item.status == 0" :small="true">mdi-border-color</v-icon>
                    <v-icon v-else-if="item.status == 1" :small="true">mdi-alert</v-icon>
                    <v-icon v-else :small="true">mdi-chevron-down-circle</v-icon>
                    {{item.status == 0 ? '回答する' : 
                      item.status == 1 ? '一時保存中' : 
                      item.status == 2 ? '回答済み' : 
                                         '送信済み'}}
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Debug from "../Lib/Debug";
import Message from "../Lib/Message";
import Mixin from "../mixins/const.js";
import Util from '../Lib/Util'

import dayjs from "dayjs";
import DatePicker from "./common/DatePicker.vue";

import CityDataAnswer from "../model/cityDataAnswer.js";

export default {
  components: { DatePicker },

  mixins: [Mixin],

  data: () => ({
    valid: true,
    loading: false,

    show: true,

    memberNo: '',
    attribute: 0,

    // テーブルヘッダー
    headers: [
      { text: "会員番号", align: "center", sortable: true, value: "memberNo", width: "10%" },
      { text: "会社名", align: "center", sortable: true, value: "companyName", width: "20%" },
      { text: "事業所名", align: "center", sortable: true, value: "officeName", width: "20%" },
      { text: "所在地", align: "center", sortable: true, value: "address", width: "30%" },
      { text: "回答", align: "center", sortable: true, value: "status", width: "20%" },
    ],

    surveyId: null,
    officeList: [],

    // 検索条件
    searchCondition: {
      screenType: 'CityDataList',
      companyId: null,
      associationId: null,
      companyName: '',
      targetMonth: '',
      prefIdList: [],
      searchString: '',
    },

    // 対象年月チェック
    isCheckDate: false,
  }),

  // ライフサイクルフック
  created: function () {
    this.attribute = this.$store.getters.getAttribute;  // 属性
    if(this.attribute == 0) {
      // 事業所ユーザ
      this.memberNo = this.$store.getters.getMemberNo;    // 会員番号
    } else {
      // 協会ユーザ
      this.memberNo = this.$store.getters.getAssociation.association_id;    // 会員番号
    }
    
    // 属性による検索条件の設定
    if(this.attribute == 0) {
      // 事業所ユーザは自会社の事業所データのみ
      this.searchCondition.companyId = this.$store.getters.getCompanyId;

      // 事業所ユーザは、自分がメール宛先にある場合のみ
      this.searchCondition.email = this.$store.getters.getUserid;

    } else if(this.attribute == 1) {
      // 都道府県協会ユーザは自協会に所属する会社のデータのみ
      this.searchCondition.associationId = this.memberNo;
    }

    // 検索条件
    const str = this.$store.getters.getStoreSearchCondition;
    Debug.log(str);
    // if(!Object.keys(str).length > 0) {
    if (str.screenType == 'CityDataList') {
      this.searchCondition = str;
    } else {
      this.searchCondition.targetMonth = dayjs().startOf('month').subtract(1, 'month').format().substr(0, 7);
    }
    this.loadList(); // 表示更新
  },

  mounted: async function () {},

  methods: {
    // 表示更新
    async loadList() {
      Debug.log("function[loadList]");
      this.loading = true;
      // this.companyId = this.$store.getters.companyId; // 会社IDをstoreから取得

      try {
        //const res = await CityData.getOfficeList(this.companyId, this.targetMonth);
        const res = await CityDataAnswer.search(this.searchCondition);
        Debug.log(res.data);
        this.officeList = res.data;
      } catch (error) {
        Message.err(error, "月次報告データ一覧を取得できませんでした");
      }

      this.loading = false;
    },

    search() {
      Debug.log("function[search]");
      this.$nextTick(() => {
        this.$refs.form.validate();
      });
      this.$store.commit('setStoreSearchCondition', this.searchCondition);
      this.loadList();
    },

    // 詳細ページに遷移
    gotoDetail(surveyId, memberNo) {
      Debug.log("function[gotoDetail]");
      this.$store.commit("setTargetSurveyId", surveyId); // クリックした調査IDをstoreに保存(新規:null、編集:sid)
      this.$store.commit("setTargetMemberNo", memberNo); // クリックした会員番号をstoreに保存

      this.$router.push("/city_data_edit"); // 同タブで開く
    },

    // 回答送信
    sendAnswer() {
      if (!confirm("回答を送信します。よろしいですか？")) return;
      alert("回答を送信しました");
    },

    // 日付の上限チェック
    checkDateUpper(limit, targetDate) {
      const tmpStartDate = limit;
      const tmpEndDate = targetDate;

      if (!tmpStartDate || !tmpEndDate) return true;

      const startDate = Util.convStringDate(tmpStartDate+'-01', '-');
      const endDatee = Util.convStringDate(tmpEndDate+'-01', '-');
      
      if (startDate <= endDatee) {
        this.isCheckDate = true;
        return dayjs(limit).format('YYYY年MM月')+'より前を設定してください';
      }
      this.isCheckDate = false;
      return true;
    },
  },

  filters: {
    formatDate(date) {
      // YYYY年MM月 になおす
      if (!date) return "";

      return dayjs(date).format("YYYY年MM月");
    },
  },

  watch: {},

  computed :{
    upperDate() {
      let date = null;
      if (this.attribute == 0) {
        // 事業所ユーザは当月より前まで選択可能
        date = dayjs().startOf('month').format('YYYY-MM');
      }
      return date;
    }
  }
};
</script>

<style scoped>
.disablecolor {
  background: #bbbbbb;
}
</style>
